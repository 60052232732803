<template>
  <v-container fluid>
    <v-card class="pa-8 pb-0">
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col cols="12" sm="6" class="border">
            <v-text-field
              dense
              outlined
              :label="this.$t('generic.lang_option') + ' 1 '"
              v-model.number="form.discount_1"
              :rules="[rules.numberRange(form.discount_1)]"
              type="number"
              :min="0"
              :max="this.maxAmountOfDiscount"
              suffix="%"
              :loading="this.loadingDiscountsMatrix"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="border">
            <v-text-field
              dense
              outlined
              :label="this.$t('generic.lang_option') + ' 2 '"
              v-model.number="form.discount_2"
              :rules="[rules.numberRange(form.discount_2)]"
              type="number"
              :min="0"
              :max="this.maxAmountOfDiscount"
              suffix="%"
              :loading="this.loadingDiscountsMatrix"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="border">
            <v-text-field
              dense
              outlined
              :label="this.$t('generic.lang_option') + ' 3 '"
              v-model.number="form.discount_3"
              :rules="[rules.numberRange(form.discount_3)]"
              type="number"
              :min="0"
              :max="this.maxAmountOfDiscount"
              suffix="%"
              :loading="this.loadingDiscountsMatrix"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="border">
            <v-text-field
              dense
              outlined
              :label="this.$t('generic.lang_option') + ' 4 '"
              v-model.number="form.discount_4"
              :rules="[rules.numberRange(form.discount_4)]"
              type="number"
              :min="0"
              :max="this.maxAmountOfDiscount"
              suffix="%"
              :loading="this.loadingDiscountsMatrix"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="px-0 text-right">
            <v-btn
              color="primary"
              class="mx-0"
              depressed
              @click="updateDiscountsMatrix"
              :loading="this.loadingDiscountsMatrix"
              :disabled="!valid"
            >
              {{ $t("generic.lang_save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { isNumeric } from "mathjs";

export default {
  name: "DiscountManagerComponent",
  data() {
    return {
      ENDPOINTS,
      Events,
      valid: false,
      maxAmountOfDiscount: 100,
      form: {
        discount_1: 0,
        discount_2: 0,
        discount_3: 0,
        discount_4: 0,
      },
      loadingDiscountsMatrix: false,
      rules: {
        numberRange: (v, min = 0) => isNumeric(v) && (v >= min && v <= this.maxAmountOfDiscount ) || this.$t("generic.lang_numberRangeInput").replace("${min}", min).replace("${max}", this.maxAmountOfDiscount),
      },
    };
  },
  methods: {
    async getDiscountsMatrix() {
      this.loadingDiscountsMatrix = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.DISCOUNT_MANAGER.GET)
        .then((res) => {
          if (res.data.success) {
            if (res.data.values) {
              this.form = { ...res.data.values };
              this.maxAmountOfDiscount = Number(res.data.maxAmountOfDiscount) || 100;
            }
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loadingDiscountsMatrix = false;
        });
    },
    async updateDiscountsMatrix() {
      if (!this.$refs.form.validate()) return;

      this.loadingDiscountsMatrix = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.MODULESETTINGS.DISCOUNT_MANAGER.UPDATE,
          this.form
        )
        .then((res) => {
          if (res.data.status) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loadingDiscountsMatrix = false;
        });
    },
  },
  mounted() {
    this.getDiscountsMatrix();
  },
};
</script>
