<template>
  <div>
    <page-title
      :heading="$t('generic.lang_discountManager')"
      :subheading="$t('generic.lang_discountManager')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <DiscountManagerComponent />
    </div>
  </div>
</template>

<script>
import PageTitle from '../../../Layout/Components/PageTitle.vue';
import DiscountManagerComponent from '@/components/settings/moduleSettings/discountManager/DiscountManagerComponent.vue';

export default {
  components: {
    DiscountManagerComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-cash icon-gradient bg-tempting-azure',
  }),
};
</script>
